/* eslint-disable */
import avatar from "../static/images/avatar.png"
import moment from 'moment';
import { toast } from 'react-toastify';
var CryptoJS = require('crypto-js')
const Cryptr = require('cryptr');
const cryptr = new Cryptr('@!#$#%$&^&()((*%@!@EFAEFW##$VSD&(^&FR^%&^(*DSFASF!@#T&&&&&#######');
// Auth variables 
var token = "a34@TYUI"
var roles = "RTYBH&84";
var userName = "RWR56$%#"
var uid = "YUI374GY"
var profileStatus = "THFKW56"
var userEmail = "TYUSNDO"


var planDetail = "DSADHSAIDAF"
var recordingDeatils = "REWCVSFTER"


// other varia bles

var startTime = "ETETet"
var endTime = "TETEWF"
var classDate = "CLDADI"
var availMin = "SDFSRRS";

function encrypt(data) {
    try {
        let encryptData = CryptoJS.AES.encrypt(
            data,
            "Dhad564h@#TYGD@3UG",
        ).toString();
        return encryptData;
    }
    catch {

    }
}
function decrypt(data) {
    try {
        let decryptData = CryptoJS.AES.decrypt(
            data,
            "Dhad564h@#TYGD@3UG",
        ).toString(CryptoJS.enc.Utf8);
        return decryptData;
    }
    catch { }
}

function getToken() {
    try {
        return decrypt(localStorage.getItem(token))
    }
    catch (err) {
        return ''
    }
}

function getName() {
    try {
        return decrypt(localStorage.getItem(userName))
    }
    catch (err) {
        return ''
    }
}

function getSt() {
    try {
        return decrypt(localStorage.getItem(startTime))
    }
    catch (err) {
        return ''
    }
}
function getEt() {
    try {
        return decrypt(localStorage.getItem(endTime))
    }
    catch (err) {
        return ''
    }
}
function setSt(time) {
    try {
        return localStorage.setItem(startTime, encrypt(time))
    }
    catch (err) {
        return ''
    }
}
function setClassDate(date) {
    try {
        return localStorage.setItem(classDate, encrypt(date))
    }
    catch (err) {
        return ''
    }
}
function getClassDate() {
    try {
        return decrypt(localStorage.getItem(classDate))
    }
    catch (err) {
        return ''
    }
}
function setEt(time) {
    try {
        return localStorage.setItem(endTime, encrypt(time))
    }
    catch (err) {
        return ''
    }
}

function getid() {
    try {
        return decrypt(localStorage.getItem(uid))
    }
    catch (err) {
        return ''
    }
}
function getEmail() {
    try {
        return decrypt(localStorage.getItem(userEmail))
    }
    catch (err) {
        return ''
    }
}

function setAvailMin(min) {
    try {
        localStorage.setItem(availMin, encrypt(min))
    }
    catch (err) {
        return ''
    }
}

function getAvailMin() {
    try {
        return decrypt(localStorage.getItem(availMin))
    }
    catch (err) {
        return 'de'
    }
}

function getRoleType() {
    try {
        return decrypt(localStorage.getItem(roles))
    }
    catch (err) {
        return ''
    }
}
function getProfileStatus() {
    try {
        return decrypt(localStorage.getItem(profileStatus))
    }
    catch (err) {
        return ''
    }
}


function saveCredential(userToken, role, id, profile, email, name) {
    try {

        if (role == "user") {
            localStorage.setItem(userName, encrypt(name));
            localStorage.setItem(token, encrypt(userToken));
            localStorage.setItem(roles, encrypt(role));
            localStorage.setItem(profileStatus, encrypt(profile))
            localStorage.setItem(uid, encrypt(id.toString()))
            localStorage.setItem(userEmail, encrypt(email))
        }
        else if (role == "tutor") {
            localStorage.setItem(token, encrypt(userToken));
            localStorage.setItem(roles, encrypt(role));
            localStorage.setItem(profileStatus, encrypt(profile))
            localStorage.setItem(uid, encrypt(id.toString()));
            localStorage.setItem(userEmail, encrypt(email))
        }
    }
    catch (err) {

    }
}


function savePlanDetails({ plandata }) {
    try {

        localStorage.setItem(planDetail, encrypt(JSON.stringify(plandata)));

    }
    catch (err) {

    }
}

function getPlanDetails() {
    try {
        return JSON.parse(decrypt(localStorage.getItem(planDetail)))
    }
    catch (err) { return '' }
}

function profileSubmitted() {
    try {
        localStorage.setItem(profileStatus, encrypt("Uploaded"))
    }
    catch (err) {

    }
}

function scroll() {
    window.scroll({
        behavior: 'smooth',
        top: 0
    })

}


function validate(field, value) {
    try {
        if (field === "name") {
            if (value === "") {
                return { isValid: false, msg: "Username is required" };
            }
            else if (value.toString().trim().length === 0) {
                return { isValid: false, msg: "Do not add white spaces" };
            }
            else {
                const reg = new RegExp(/^[a-zA-Z ,.\u0600-\u06FF-]*$/gi);
                const res = reg.test(value.replace(/\n/g," "));
                if (res) {
                    return { isValid: true }
                } else {
                    return { isValid: false, msg: "Please add formated data" };
                } 
            }
        }
        else if (field === "password") {
            if (value === "") {
                return { isValid: false, msg: "password required" };
            }
            else if (value.trim().length === 0) {
                return { isValid: false, msg: "Do not add white spaces" };
            }
            else if (value.length < 8) {
                return { isValid: false, msg: "weak password" };
            }
            else {
                return { isValid: true }
            }
        }
        else if (field === "otp") {
            if (value === "") {
                return { isValid: false, msg: "otp required" };
            }
            else if (value.trim().length === 0) {
                return { isValid: false, msg: "Do not add white spaces" };
            }
            else if (value.length != 4) {
                return { isValid: false, msg: "Enter Valid Otp" };
            }
            const reg = new RegExp('^[0-9]+$');
            const res = reg.test(value);
            if (!res) {
                return { isValid: false, msg: "Otp have only digits" };
            }
            else {
                return { isValid: true };
            }
        }
        else if (field === "phno") {
            if (value === "") {
                return { isValid: false, msg: "phone number required" };
            }
            else if (value.trim().length === 0) {
                return { isValid: false, msg: "Do not add white spaces" };
            }
            else if (value.length < 8 || value.length > 13) {
                return { isValid: false, msg: "Invalid phone number" };
            }
            const reg = new RegExp('^[0-9]+$');
            const res = reg.test(value);
            if (!res) {
                return { isValid: false, msg: "Mobile Number have only digits" };
            }
            else {
                return { isValid: true };
            }
        }

        else if (field === "email") {
            const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            const test = reg.test(value.toLowerCase());
            if (!test) {
                return { isValid: false, msg: 'Please enter a valid email' };
            }
            else {
                return { isValid: true };
            }
        }
        else {
            return { isValid: false, msg: 'Invalid field' };
        }
    } catch (err) {
        return { isValid: false, msg: err };
    }
}


function isLoggedIn() {
    try {
        const token = getToken();
        const role = decrypt(localStorage.getItem(roles));
        if (token && role == "user") {
            return { role: 'user', res: true };
        }
        else if (token && role == "tutor") {
            return { role: 'tutor', res: true };
        }
        return { role: 'anonymous', res: false };
    }
    catch {
        return { role: 'anonymous', res: false };
    }
}
function logOut() {
    try {
        localStorage.removeItem(token);
        localStorage.removeItem(roles)
        localStorage.removeItem(uid);
        localStorage.removeItem(profileStatus)
        localStorage.clear()
    }
    catch {
        return { role: 'anonymous', res: false };
    }
}

const month = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"]

function getDOB(date) {
    const currDate = new Date(date)
    return currDate.getDate() + " " + month[currDate.getMonth()] + " " + currDate.getFullYear()
}

function getCuurentDate() {
    const currDate = new Date()

    const date = currDate.getUTCDate() + " " + month[currDate.getUTCMonth()] + " " + currDate.getUTCFullYear();
    return date
}
const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

function getDay() {
    const currDate = new Date()
    return days[currDate.getDay()]
}

function getTime() {
    const currDate = new Date()
    const ampm = currDate.getHours() > 12 ? "PM" : "AM";
    const hours = currDate.getHours() > 12 ? currDate.getHours() - 12 : currDate.getHours();
    return (hours.toString().length === 1 ? "0" + hours : hours) + " : " + (currDate.getMinutes().toString().length === 1 ? "0" + currDate.getMinutes() : currDate.getMinutes()) + " " + ampm;
}

function convertToTime(date) {
    const currDate = new Date(date)
    const ampm = currDate.getHours() > 12 ? "PM" : "AM";
    const hours = currDate.getHours() > 12 ? currDate.getHours() - 12 : currDate.getHours();
    return (hours.toString().length === 1 ? "0" + hours : hours) + " : " + (currDate.getMinutes().toString().length === 1 ? "0" + currDate.getMinutes() : currDate.getMinutes()) + " " + ampm;
}

function getDateDifference(date1) {
    const newDate1 = new Date(date1)
    const newDate2 = new Date()
    const diffDays = Math.ceil(Math.abs(newDate2 - newDate1) / (1000 * 60 * 60 * 24));
    return diffDays

}

const generateClassUrl = (tutorId, userId) => {
    let str = "@-abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
    let channelName = "";
    for (var i = 0; i < 10; i++) {
        channelName = channelName + str[Math.floor(Math.random() * str.length)]
    }
    const tutorUrl = `/tutor/${channelName}/${tutorId}/${userId}`
    const userUrl = `/user/${channelName}/${userId}/${tutorId}`
    return { tutorUrl, userUrl }
}

const genCode = () => {
    let otp = "";
    for (var i = 0; i < 6; i++) {
        otp = otp + Math.floor((Math.random() * 9) + 1).toString();
    }
    return parseInt(otp);

}

const decodeAppId = (appId) => {
    const decryptedAppId = cryptr.decrypt(appId);
    return decryptedAppId
}


const isClassComplete = ({ startTime, endTime, availableMinutes, currentMinutes }) => {
    if (currentMinutes >= availableMinutes) {
        return true
    }
    const currentTime = moment().format('HH:mm')
    const classEndTime = moment(endTime, 'HH:mm').format('HH:mm')
    const classStartTime = moment(startTime, 'HH:mm').format('HH:mm')
    if (currentTime >= classEndTime || currentMinutes <= classStartTime) {
        return true
    }
    else {
        return false
    }
}
const checkSecurity = (role) => {
    try {
        let email = getEmail(), status = getProfileStatus(), id = getid(), token = getToken()
        if (email || token || status || id) {
            if (role == "user") {
                if (!email || !token || !status || !id) {
                    localStorage.clear()
                    return { res: false }
                } else {
                    return { res: true }
                }
            }
            else if (role == "tutor") {
                if (!email || !token || !status || !id) {
                    localStorage.clear()
                    return { res: false }
                } else {
                    return { res: true }
                }
            }
            else {

                localStorage.clear()
                return { res: false }
            }
        }
        else {
            return { res: true }
        }
    }
    catch (err) {

        localStorage.clear()
        return { res: false }
    }

}

function saveRecordingDetails({ channelName, resourceId, sid, createdAt, recordingUserId }) {
    try {
        const data = {
            isExist: true,
            channelName: channelName,
            resourceId: resourceId,
            sid: sid,
            createdAt: createdAt,
            recordingUserId: recordingUserId
        }
        localStorage.setItem(recordingDeatils, encrypt(JSON.stringify(data)))
    }
    catch (err) {
    }
}

function getRecordingDetails() {
    try {
        const data = decrypt(localStorage.getItem(recordingDeatils))
        if (data) {
            return JSON.parse(data)
        }
        return { isExist: false }
    }
    catch (err) {
        return { isExist: false }
    }
}

function removeRecordingDetails() {
    try {
        localStorage.removeItem(recordingDeatils)
    }
    catch (err) {

    }
}

const isUserCanJoinClass = ({ classDate, startTime, endTime }) => {
    if (endTime && startTime) {
        const beforeTime = moment(startTime, 'HH:mm').subtract(1, 'm').toDate()
        if (moment().format("YYYY-MM-DD") == moment(classDate).format("YYYY-MM-DD") && moment(beforeTime, 'HH:mm').format('HH:mm') <= moment().format("HH:mm") && moment(endTime, 'HH:mm').format('HH:mm') >= moment().format("HH:mm")) {
            return true
        } else {
            return false
        }
    } else if (startTime && !endTime) {
        const beforeTime = moment(startTime, 'HH:mm').subtract(1, 'm').toDate()
        if (moment().format("YYYY-MM-DD") == moment(classDate).format("YYYY-MM-DD") && moment(beforeTime, 'HH:mm').format('HH:mm') <= moment().format("HH:mm")) {
            return true
        } else {
            return false
        }
    }
    else {
        const beforeTime = moment(startTime, 'HH:mm').subtract(1, 'm').toDate()
        if (moment().format("YYYY-MM-DD") == moment(classDate).format("YYYY-MM-DD") && moment(beforeTime, 'HH:mm').format('HH:mm') <= moment().format("HH:mm")) {
            return true
        } else {
            return false

        }
    }

}

const handleOutSide = (e, id, cb) => {
    try {
        if (e.target.id === id) {
            cb()
        }
    } catch {
        cb()
    }
}


const showMessage = (msg, type) => {
    switch (type) {
        case "info":
            toast.info(`${msg}`, {
                position: "top-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            break;
        case "warning":
            toast.warn(`${msg}`, {
                position: "top-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            break;
        case "error":
            toast.error(`${msg}`, {
                position: "top-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            break;
        case "success":
            toast.success(`${msg}`, {
                position: "top-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            break;

        default:
            toast(`${msg}`, {
                position: "top-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
    }
}

function replaceS3Url(url) {
    const invalidUrls = [
        'https://twiker.s3.ap-south-1.amazonaws.com',
        'https://api.saydhad.com'
    ];
    const oldBaseUrl = "https://s3bucketstaqo.s3.amazonaws.com";
    const newBaseUrl = "https://saydhad-new.s3.ap-south-1.amazonaws.com";
  
    // Check if the input is not a valid URL
    try {
        new URL(url);
    } catch (e) {
        // Return the input as is if it's not a valid URL
        return url;
    }

    // Check if the URL contains an invalid URL
    if (url && url !== null && invalidUrls.some(invalidUrl => url.includes(invalidUrl))) {
        return avatar; // assuming avatar is defined elsewhere
    }

    // Check if the URL contains the old base URL
    if (url && url.includes(oldBaseUrl)) {
        const newUrl = url.replace(oldBaseUrl, newBaseUrl);
        let updatedUrl = newUrl.replace(/([^:])\/\//g, '$1/'); // Remove double slashes (except after protocol)
        return updatedUrl;
    }

    return url;
}

  function replaceVideoS3Url(url) {
    const invalidUrls = [
        'https://twiker.s3.ap-south-1.amazonaws.com',
        'https://api.saydhad.com'
      ];
    const oldBaseUrl = "https://s3bucketstaqo.s3.amazonaws.com";
    const newBaseUrl = "https://saydhad-new.s3.ap-south-1.amazonaws.com";

    try {
        new URL(url);
    } catch (e) {
        // Return the input as is if it's not a valid URL
        return url;
    }
  
    if(url && url !== null && invalidUrls.some(invalidUrl => url.includes(invalidUrl))){
      return null
    }
  
    // Check if the URL contains the old base URL
    if (url && url !== null && url.includes(oldBaseUrl)) {
      const newUrl = url.replace(oldBaseUrl, newBaseUrl);
      let updatedUrl = newUrl.replace(/([^:])\/\//g, '$1/');
      return updatedUrl;
    }
    return url;
  }

export default {
    encrypt, decrypt, getToken, saveCredential, scroll, validate, isLoggedIn, getid,
    getDOB, logOut, getProfileStatus, getCuurentDate, getTime, getDay, profileSubmitted, getEmail,
    getDateDifference, convertToTime, generateClassUrl, setSt, setEt, getSt, getEt, setClassDate, getClassDate,
    genCode, getRoleType, decodeAppId, setAvailMin, getAvailMin, getName, savePlanDetails, getPlanDetails, isClassComplete, checkSecurity,
    saveRecordingDetails, getRecordingDetails, removeRecordingDetails, isUserCanJoinClass, handleOutSide, showMessage , replaceS3Url , replaceVideoS3Url
}