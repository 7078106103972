import React, { useState, useEffect } from 'react'
import parot from '../static/images/parrot-1.png'
import pops from '../component/PopUps'
import tutor from '../controller/tutor'
import helper from '../helper/index'
import Backdrop from '../component/Backdrop'
import { useTranslation } from "react-i18next";
import moment from 'moment'
export default function BasicInfo({ next }) {
    const { t } = useTranslation();
    const [openPop, setOpenPop] = useState({ name: '', open: false })
    const [basicInfo, setBasicInfo] = useState()
    const [workExp, setworkExp] = useState([])
    const [education, setEducation] = useState([])
    const [language, setLanguage] = useState([])
    const [pageload, setPageload] = useState(true)
    const [certificate, setCertificate] = useState([])

    useEffect(() => {
        Promise.all([
            getBasicInfo(),
            getLanguage(),
            getWorkExperience(),
            getEducation(),
            getCertificate()
        ]).then(() => { setPageload(false) })
        return () => { }
    }, [])


    const getBasicInfo = async () => {
        await tutor.getBasicInfo(helper.getid())
            .then((res) => {
                if (res.data.status) {
                    setBasicInfo(res.data.result)
                    setPageload(false)

                }
            })
    }

    const getWorkExperience = async () => {
        await tutor.getworkExperience(helper.getid())
            .then((res) => {
                if (res.data.status) {
                    setworkExp(res.data.result)
                }
            })
    }

    const getEducation = async () => {
        await tutor.getEducation(helper.getid())
            .then((res) => {
                if (res.data.status) {
                    setEducation(res.data.result)


                }

            })
    }

    const getLanguage = async () => {
        await tutor.getLanguage(helper.getid())
            .then((res) => {
                if (res.data.status) {
                    setLanguage(res.data.result)
                }
            })
    }

    const getCertificate = async () => {
        await tutor.getCertificate(helper.getid())
            .then((res) => {
                if (res.data.status) {
                    setCertificate(res.data.result)
                }
            })
    }

    const delWork = async (id, index) => {
        await tutor.deleteWorkExperience({ tutorId: helper.getid(), id: id })
            .then((res) => {
                if (res.data.status) {
                    let temp = [...workExp]
                    temp.splice(index, 1)
                    setworkExp(temp)
                }
            })
    }

    const delEducation = async (id, index) => {
        await tutor.deleteEducation({ tutorId: parseInt(helper.getid()), id: id })
            .then((res) => {
                if (res.data.status) {
                    let temp = [...education]
                    temp.splice(index, 1)
                    setEducation(temp)
                }
            })
    }

    const delLanguage = async (id, index) => {
        await tutor.delLanguage({ tutorId: parseInt(helper.getid()), id: id })
            .then((res) => {
                if (res.data.status) {
                    let temp = [...language]
                    temp.splice(index, 1)
                    setLanguage(temp)
                }

            })
    }

    const delCertificate = async (id, index) => {
        await tutor.delCertificate({ tutorId: parseInt(helper.getid()), id: id })
            .then((res) => {
                if (res.data.status) {
                    let temp = [...certificate]
                    temp.splice(index, 1)
                    setCertificate(temp)
                }
            })
    }

    const moveNext = () => {
        if (basicInfo) {
            if (!basicInfo.profilePicture || !basicInfo.name || !basicInfo.dateOfBirth || !basicInfo.phoneNo || !basicInfo.introduction || !basicInfo.profileVideo) {
                helper.showMessage("please complete general information", "warning")
                return
            }
            else if (!basicInfo.teachingStyle || !basicInfo.aboutMe || language.length === 0) {
                helper.showMessage("please complete about, teaching style and languages", "warning")

                return
            }
            else if (workExp.length === 0) {
                helper.showMessage("please add work experience", "warning")

                return
            }
            else if (education.length === 0) {
                return helper.showMessage("please add languages you know", "warning")
            }
            else if (certificate.length === 0) {
                return helper.showMessage("please add alteast one certificate", "warning")
            }
            else {
                next()
                return
            }
        } else {
            return helper.showMessage("please  complete your profile", "warning")

        }
    }

    return (
        <>
            <div className="row gx-0 mx-0">

                <div className="col-lg-11 mx-auto pt-3 ">
                    <div className='p-sticky tp-0 py-3 w-100 bg-white' style={{ zIndex: 1 }}>
                        <div className="row gx-0 mx-0">
                            <div className="col-lg-6 col-md-5 col-12 ms-900 mb-2">
                                <h4>{t('tutorOnBoard.basicInfo.tutor')} <span className="tx-green">{t('tutorOnBoard.basicInfo.profile')}</span></h4>
                                <p className="sub-heading">{t('tutorOnBoard.basicInfo.subHeading')}</p>
                            </div>
                            <div className="col-lg-6 col-md-7 col-12 d-flex justify-content-lg-end justify-content-md-end mb-2 align-items-center">
                                <button className="btn btn-login fs-14" onClick={() => moveNext()}>{t('tutorOnBoard.basicInfo.nextdig')}</button>
                            </div>
                        </div>
                    </div>

                    <div className="alert  fs-14 ms-500 tx-lt-grey py-2 my-3 px-2 d-flex align-items-center">
                        <img width="50px" src={parot} alt="parrot" />
                        <span> {t('tutorOnBoard.basicInfo.para1')} <span className="ms-900">{t('tutorOnBoard.basicInfo.para2')}  </span> </span>
                    </div>
                    {
                        !pageload ?
                            <section className="row  gx-0 mx-0 f-fred basic-info">
                                <div className="col-lg-9 col-md-10 col-12 px-1 mx-auto">
                                    <h4 className="my-3 tx-lt-grey">{t('tutorOnBoard.basicInfo.generalInfo.heading')}<small>*</small></h4>
                                    <ul>
                                        <li>
                                            <div className="row gx-0 mx-0 py-2">
                                                <div className="col-lg-1 col-md-2 col-2 all-center">
                                                    {!basicInfo && <img width="50px" height="50px" className="rounded-circle" src="https://www.incimages.com/uploaded_files/image/1920x1080/getty_143919450_970647970450097_58844.jpg" alt="pic" />}
                                                    {basicInfo && basicInfo.profilePicture && <img width="50px" height="50px" className="rounded-circle" src={helper.replaceS3Url(basicInfo.profilePicture)} alt="pic" />}
                                                    {basicInfo && !basicInfo.profilePicture && <img width="50px" height="50px" className="rounded-circle" src="https://www.incimages.com/uploaded_files/image/1920x1080/getty_143919450_970647970450097_58844.jpg" alt="pic" />}
                                                </div>
                                                <div className="col-lg-8 col-md-4 col-6 d-flex flex-column justify-content-center px-2">
                                                    <p className="lh-18 fs-14">{basicInfo ? basicInfo.name : "Anonymous"}</p>
                                                    <p className="ms-500 fs-12 tx-lt-grey">{t('general.name')}</p>
                                                </div>
                                                <div className="col-lg-3 col-md-6 col-4 all-center  justify-content-end ">
                                                    <button className="btn edit-info" onClick={() => setOpenPop({ ...openPop, name: "basicInfo", open: true })}>{t('tutorOnBoard.basicInfo.generalInfo.basicInfoBtn')}</button>
                                                </div>

                                                {basicInfo &&
                                                    <>

                                                        <div className="col-lg-6 col-6 fs-14 text-justify mt-2 px-2">

                                                            <span className="fs-14 ">{helper.getDOB(basicInfo.dateOfBirth)}</span>
                                                            <p className="lh-18 fs-12 ms-500 tx-lt-grey">{t('general.dob')}</p>
                                                        </div>
                                                        <div className="col-lg-6 col-6 fs-14 text-justify mt-2 px-2">

                                                            <span className="fs-14 text-capitalize">{basicInfo.from}</span>
                                                            <p className="lh-18 fs-12 tx-lt-grey ms-500">{t('general.country')}</p>
                                                        </div>
                                                        <div className="col-lg-6 col-6 fs-14 text-justify mt-2 px-2">

                                                            <span className="fs-14">{basicInfo.phoneNo}</span>
                                                            <p className="lh-18 fs-12 tx-lt-grey ms-500">{t('general.mobileNo')}</p>
                                                        </div>

                                                    </>}
                                            </div>

                                        </li>

                                        <li>
                                            <div className="row gx-0 mx-0 py-2">

                                                <div className="col-lg-9 col-md-8 col-7 d-flex flex-column justify-content-center px-2">
                                                    <p className="lh-18 fs-14">{t('tutorOnBoard.basicInfo.generalInfo.intro')}</p>
                                                    <p className="ms-500 fs-12">{t('tutorOnBoard.basicInfo.generalInfo.title1')}</p>

                                                </div>
                                                <div className="col-lg-3  col-md-4 col-5 all-center  justify-content-end ">
                                                    <button className="btn edit-info" onClick={() => setOpenPop({ ...openPop, name: "basicInfo", open: true })}>{t('tutorOnBoard.basicInfo.generalInfo.introBtn')}</button>

                                                </div>
                                                {basicInfo &&
                                                    <div className="col-12 fs-14 text-justify ms-500  mt-2 px-2">
                                                        <p> {basicInfo.introduction}</p>
                                                    </div>}
                                            </div>
                                        </li>

                                        <li>
                                            <div className="row gx-0 mx-0 py-2">
                                                <div className="col-lg-9 col-md-8 col-7 d-flex flex-column justify-content-center px-2">
                                                    <p className="lh-18 fs-14">{t('tutorOnBoard.basicInfo.generalInfo.video')}</p>
                                                    <p className="ms-500 fs-12">{t('tutorOnBoard.basicInfo.generalInfo.title2')}</p>

                                                </div>
                                                <div className="col-lg-3  col-md-4 col-5 all-center  justify-content-end ">
                                                    <button className="btn edit-info" onClick={() => setOpenPop({ ...openPop, name: "basicInfo", open: true })}>{t('tutorOnBoard.basicInfo.generalInfo.videoBtn')}</button>
                                                </div>
                                                <div className="col-12 mt-2">
                                                    {basicInfo && basicInfo.profileVideo && <video className="br-1 bg-smoke d-block mx-auto" height="230px" src={helper.replaceVideoS3Url(basicInfo.profileVideo)} controls ></video>}
                                                </div>
                                            </div>
                                        </li>
                                    </ul>

                                    <h4 className="mt-3 mb-2 tx-lt-grey">{t('tutorOnBoard.basicInfo.about.heading')}<small>*</small></h4>

                                    <ul><li>
                                        <div className="row gx-0 mx-0 py-2">

                                            <div className="col-lg-9 col-md-8 col-7 d-flex flex-column justify-content-center px-2">
                                                <p className="lh-18 fs-14">{t('tutorOnBoard.basicInfo.about.style')}</p>
                                                <p className="ms-500 fs-12">{t('tutorOnBoard.basicInfo.about.title1')}</p>

                                            </div>
                                            <div className="col-lg-3 col-md-4 col-5 all-center  justify-content-end ">
                                                <button className="btn edit-info" onClick={() => setOpenPop({ ...openPop, name: "teaching-style", open: true })}>{t('tutorOnBoard.basicInfo.about.styleBtn')}</button>

                                            </div>
                                            {basicInfo &&
                                                <div className="col-12 fs-14 text-justify ms-500 mt-2 px-2">
                                                    <p> {basicInfo.teachingStyle}</p>
                                                </div>}
                                        </div>

                                    </li>



                                        <li>
                                            <div className="row gx-0 mx-0 py-2">

                                                <div className="col-lg-9 col-md-8 col-7 d-flex flex-column justify-content-center px-2">
                                                    <p className="lh-18 fs-14">{t('tutorOnBoard.basicInfo.about.aboutyou')}</p>
                                                    <p className="ms-500 fs-12">{t('tutorOnBoard.basicInfo.about.title2')}</p>

                                                </div>
                                                <div className="col-lg-3 col-md-4 col-5 all-center  justify-content-end ">
                                                    <button className="btn edit-info" onClick={() => setOpenPop({ ...openPop, name: "teaching-style", open: true })}>{t('tutorOnBoard.basicInfo.about.aboutBtn')}</button>

                                                </div>
                                                {basicInfo &&
                                                    <div className="col-12 fs-14 text-justify ms-500 mt-2 px-2">
                                                        <p> {basicInfo.aboutMe}</p>
                                                    </div>}
                                            </div>
                                        </li>

                                        <li>
                                            <div className="row gx-0 mx-0 py-2">

                                                <div className="col-lg-9 col-md-8 col-7 d-flex flex-column justify-content-center px-2">
                                                    <p className="lh-18 fs-14">{t('tutorOnBoard.basicInfo.about.lng')}</p>
                                                    <p className="ms-500 fs-12">{t('tutorOnBoard.basicInfo.about.title3')}</p>

                                                </div>
                                                <div className="col-lg-3 col-md-4 col-5 all-center  justify-content-end ">
                                                    <button className="btn edit-info" onClick={() => setOpenPop({ ...openPop, name: "teaching-style", open: true })}>{t('tutorOnBoard.basicInfo.about.lngBtn')}</button>
                                                </div>

                                                <div className="col-12 px-2 mt-2">
                                                    {language.map((lng, index) =>
                                                        <span key={lng.id} className="lang-tags text-capitalize fs-12 mr-2 mb-2 wrap d-inline-block">
                                                            {lng.language}( {lng.dialect}-{lng.fluency} ) <i className="far fa-times-circle float-right fs-18 pointer" onClick={() => delLanguage(lng.id, index)}></i></span>
                                                    )}
                                                </div>
                                            </div>

                                        </li>

                                    </ul>

                                    <h4 className="mt-3 mb-2 tx-lt-grey">{t('tutorOnBoard.basicInfo.exp.heading')}<small>*</small></h4>

                                    <ul><li>
                                        <div className="row gx-0 mx-0 py-2">

                                            <div className="col-lg-9 col-md-8 col-7 d-flex flex-column justify-content-center px-2">
                                                <p className="lh-18 fs-14">{t('tutorOnBoard.basicInfo.exp.workExp')}</p>
                                                <p className="ms-500 fs-12">{t('tutorOnBoard.basicInfo.exp.title1')}</p>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-5 all-center  justify-content-end ">
                                                <button className="btn edit-info" onClick={() => setOpenPop({ ...openPop, name: "work", open: true })}>{t('tutorOnBoard.basicInfo.exp.expBtn')}</button>
                                            </div>

                                            {workExp.map((work, index) => {
                                                return (
                                                    <div key={work.id} className="col-12 fs-12 mt-1 ms-500 col-7 px-2">
                                                        <p className="f-fred lh-18 fs-14">{work.title} <i className="far fa-times-circle float-right fs-18 pointer" onClick={() => delWork(work.id, index)}></i></p>
                                                        <p className="lh-18 fs-14">{work.from} To {work.to}</p>
                                                        <p className="my-2 text-justify fs-14">{work.description}</p>
                                                    </div>
                                                )
                                            })}

                                        </div>
                                    </li>



                                        <li>
                                            <div className="row gx-0 mx-0 py-2">

                                                <div className="col-lg-8 col-md-8 col-7 d-flex flex-column justify-content-center px-2">
                                                    <p className="lh-18 fs-14">{t('tutorOnBoard.basicInfo.exp.edu')}</p>
                                                    <p className="ms-500 fs-12">{t('tutorOnBoard.basicInfo.exp.title2')}</p>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-5 all-center  justify-content-end ">
                                                    <button className="btn edit-info " style={{ whiteSpace: 'pre-wrap' }} onClick={() => setOpenPop({ ...openPop, name: "education", open: true })}>{t('tutorOnBoard.basicInfo.exp.eduBtn')}</button>
                                                </div>
                                                <div className="col-12">
                                                    {education && education.map((work, index) => {
                                                        return (
                                                            <div key={work.id} className="col-12 fs-12 mt-2 ms-500 col-7 px-2">
                                                                <p className="f-fred lh-18 fs-14">{work.university}  <i className="far fa-times-circle float-right fs-18 pointer" onClick={() => delEducation(work.id, index)}></i></p>
                                                                <p className="lh-18 fs-14">{work.degree} ({work.from} <b>To</b> {work.to} )</p>

                                                            </div>
                                                        )
                                                    })}
                                                </div>


                                            </div>
                                        </li>

                                        <li>
                                            <div className="row gx-0 mx-0 py-2">

                                                <div className="col-lg-9 col-md-8 col-7 d-flex flex-column justify-content-center px-2">
                                                    <p className="lh-18 fs-14">{t('tutorOnBoard.basicInfo.exp.cert')}</p>
                                                    <p className="ms-500 fs-12">{t('tutorOnBoard.basicInfo.exp.title3')}</p>

                                                </div>
                                                <div className="col-lg-3 col-md-4 col-5 all-center  justify-content-end ">
                                                    <button className="btn edit-info" style={{ whiteSpace: 'pre-wrap' }} onClick={() => setOpenPop({ ...openPop, name: "certificate", open: true })}>{t('tutorOnBoard.basicInfo.exp.certBtn')}</button>

                                                </div>

                                                <div className="col-12">
                                                    {certificate && certificate.map((cert, index) => {
                                                        return (
                                                            <div key={cert.id} className="col-12 fs-12 mt-2 ms-500 col-7 px-2">
                                                                <p className="f-fred lh-18 fs-14">{cert.title}  <i className="far fa-times-circle float-right fs-18 pointer" onClick={() => delCertificate(cert.id, index)}></i></p>
                                                                <p className="lh-18 fs-14"><a href={cert.file_name} className="link" target="_blank" rel="noreferrer"> {cert.issue_authority} - {moment(cert.certificate_date).format("DD MMM, YYYY")}</a></p>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>



                                        </li>

                                    </ul>
                                </div>
                            </section> : <Backdrop />}

                </div>

            </div>

            {
                openPop.open && openPop.name === "basicInfo" ?
                    <pops.BasicInfoPop
                        cb={() => getBasicInfo()}
                        close={() => setOpenPop({ name: '', open: false })}
                        data={basicInfo} /> :
                    openPop.open && openPop.name === "teaching-style" ?
                        <pops.TeachingPop
                            languages={language}
                            cb={() => { getBasicInfo(); getLanguage() }}
                            close={() => setOpenPop({ name: '', open: false })}
                            data={basicInfo}
                        /> :
                        openPop.open && openPop.name === "work" ?
                            <pops.WorkExperiencePop
                                cb={() => getWorkExperience()}
                                close={() => setOpenPop({ name: '', open: false })} /> :
                            openPop.open && openPop.name === "education" ?
                                <pops.EducationPop
                                    cb={() => getEducation()}
                                    close={() => setOpenPop({ name: '', open: false })} /> :
                                openPop.open && openPop.name === "certificate" ?
                                    <pops.CertificatePop
                                        cb={() => getCertificate()}
                                        close={() => setOpenPop({ name: '', open: false })} /> :
                                    ''

            }
        </>
    )
}
