/* eslint-disable */
import React, { useRef, useState, useEffect } from "react";
import Webcam from "react-webcam";
import RecordRTC from "recordrtc";

export const WebcamStreamCapture = ({ onCapture , onClose }) => {
  const webcamRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recorder, setRecorder] = useState(null);
  const [time, setTime] = useState({ minutes: 0, seconds: 0 });
  const [facingMode, setFacingMode] = useState("user"); // Default to front camera

  let interval = useRef(null);

  // Timer Management
  useEffect(() => {
    if (capturing) {
      interval.current = setInterval(() => {
        setTime((prev) => {
          const newSeconds = (prev.seconds + 1) % 60;
          const newMinutes =
            prev.seconds === 59 ? prev.minutes + 1 : prev.minutes;
          return { minutes: newMinutes, seconds: newSeconds };
        });
      }, 1000);
    } else {
      clearInterval(interval.current);
    }
    return () => clearInterval(interval.current);
  }, [capturing]);

  // Start Recording
  const handleStartCaptureClick = async () => {
    try {
      const stream = webcamRef.current?.stream;
      if (!stream) {
        alert("Camera access is required!");
        return;
      }

      setCapturing(true);
      setTime({ minutes: 0, seconds: 0 });

      const options = {
        type: "video",
        mimeType: "video/mp4", // Compatible with iOS Safari
      };
      const recordRTC = new RecordRTC(stream, options);
      recordRTC.startRecording();
      setRecorder(recordRTC);
    } catch (error) {
      console.error("Error starting capture:", error);
      alert("Error accessing camera or microphone.");
    }
  };

  // Stop Recording
  const handleStopCaptureClick = () => {
    if (!recorder) {
      console.error("Recorder not initialized");
      return;
    }

    setCapturing(false);
    recorder.stopRecording(() => {
      const blob = recorder.getBlob();
      const file = new File([blob], `intro_vid_${Date.now()}.mp4`, {
        type: "video/mp4",
      });
      onCapture(file);
    });
  };

  // Toggle Camera
  const toggleCamera = () => {
    setFacingMode((prev) => (prev === "user" ? "environment" : "user"));
  };

  return (
    <div className="all-center flex-column">
      {/* Webcam Component */}
      <Webcam
        style={{ width: "100%" }}
        audio={true}
        ref={webcamRef}
        videoConstraints={{ facingMode: facingMode }}
      />

      {capturing ? (
        <div className="d-flex justify-content-between align-items-center w-100 px-2">
          {/* Timer Display */}
          <div className="br-1 ms-700 fs-18 tx-green">
            <span>
              <i className="fas fa-compact-disc"></i>
            </span>
            {String(time.minutes).padStart(2, "0")} :{" "}
            {String(time.seconds).padStart(2, "0")}
          </div>

          {/* Stop Button */}
          <button
            className="btn btn-login mr-2 my-2"
            onClick={handleStopCaptureClick}
          >
            Stop Capture
          </button>
        </div>
      ) : (
        <div className="d-flex justify-content-between flex- px-3 w-100 align-items-center">
          {/* Start Button */}
          <button
            className="btn btn-tutor-profile my-2"
            onClick={handleStartCaptureClick}
          >
            Start
          </button>
          <button className="btn btn-tutor-profile my-2" onClick={toggleCamera}>
            Switch
          </button>
          <button
            className="btn btn-tutor-profile my-2"
            onClick={onClose}
          >
            cancel
          </button>
          {/* Switch Camera Button */}
        </div>
      )}
    </div>
  );
};
