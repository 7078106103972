/* eslint-disable */
import React, { useState, useEffect } from 'react'
import agora from '../controller/agora'
import avatar from '../static/images/avatar.png'
import { useParams } from 'react-router-dom'
import { DualRing } from 'react-awesome-spinners'
const goalType = {
    1: "PROFESSIONAL DEVELOPMENT",
    2: "ACADMICS",
    3: "TRAVEL",
    4: "PERSONAL GROWTH",
}
const level = {
    0: "Basic",
    1: "Intermediate",
    2: "Advance"
}
export default function StudentProfile({ open, onClose,cb }) {
    const { channelName } = useParams()
    const [state, setState] = useState({
        data: '',
        load: true
    })
    useEffect(() => { getInfo() }, [open])
    const getInfo = async () => {

        await agora.getStudentInfo(channelName).then((res) => {
            if (res.data.status) {
                setState({ ...state, data: res.data.result, load: false })
                cb(res.data.result)
            }
        })
    }
    return (
        <>
            {open &&
                <div className="all-center popup-modal">
                    <div className="left-popup p-fix tp-0 rt-0  px-4 py-4 sm" >
                        <p className="ms-900 fs-24  mb-0">Student <span className="tx-green">details</span><span className="float-right fs-24 tx-grey pointer" onClick={onClose}><i className="far fa-times-circle hover-shadow"></i></span></p>
                        <p className="sub-heading">Student</p>

                        {state.data && !state.load ?

                            <section className='mt-3'>
                                <div className="row gx-0 mx-0 p-2 br-1" style={{ backgroundColor: '#F4F6FB' }}>
                                    <div className="col-12">
                                        <div className='mr-2 mb-3'>
                                            <img style={{ height: '50px', width: '50px' }} className="rounded-circle mx-1" src={state.data.profilePicture ? helper.replaceS3Url(state.data.profilePicture) : avatar} alt="tutor" />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-4">
                                        <p className="ms-500">Full Name</p>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-8 d-flex justify-content-center">
                                        <p className="ms-900 ml-auto text-capitalize">{state.data.name}</p>
                                    </div>
                                     
                                    <div className="col-12 border-dot my-1"></div>
                                    <div className="col-lg-4 col-md-4 col-4">
                                        <p className="ms-500">Country</p>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-8 d-flex justify-content-center">
                                        <p className="ms-900  ml-auto">{state.data.country}</p>
                                    </div>
                                    <div className="col-12 border-dot my-1"></div>
                                    <div className="col-lg-4 col-md-4 col-4">
                                        <p className="ms-500">Account for</p>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-8 d-flex justify-content-center">
                                        <p className="ms-900  ml-auto">{state.data.selfAccount ? "MySelf" : "Kids"}</p>
                                    </div>
                                    <div className="col-12 border-dot my-1"></div>
                                    <div className="col-lg-4 col-md-4 col-4">
                                        <p className="ms-500">Level</p>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-8 d-flex justify-content-center">
                                        <p className="ms-900  ml-auto">{level[state.data.levelType]}</p>
                                    </div>
                                    <div className="col-12 border-dot my-1"></div>
                                    <div className="col-lg-4 col-md-4 col-4">
                                        <p className="ms-500">Goal</p>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-8 d-flex justify-content-center">
                                        <p className="ms-900  ml-auto text-capitalize">{goalType[state.data.goalType]}</p>
                                    </div>
                                </div>
                            </section> :
                            <div className='all-center my-4'>
                                <DualRing size={40} color="#20bef8" />
                            </div>}

                    </div>
                </div>
            }
        </>
    )
}
