/* eslint-disable */
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import Testimonial from '../component/Testimonial'
import store from '../redux/AppState'
import { useHistory, useParams } from 'react-router'
import RateTutor from '../component/RateTutor'
import avatar from '../static/images/avatar.png'
import user from '../controller/user'
import helper from '../helper/index'
import ReactStars from "react-rating-stars-component";
import Backdrop from '../component/Backdrop'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'

function UserTutors() {
    const { t } = useTranslation();
    const reduxState = useSelector(state => state)
    const { id, courseName } = useParams()
    const history = useHistory()
    const [data, setData] = useState()
    const [pageload, Setpageload] = useState(true)
    const [isFav, setIsFav] = useState(false)
    const [state, setState] = useState({
        openRatePop: false,
        data: null
    })

    useEffect(() => {
        apiCall()
        return () => { }
        //  eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const apiCall = async () => {
        await user.getTutorById(id, helper.getid())
            .then((res) => {
                if (res.data.status) {
                    setData(res.data.result[0])
                    setIsFav(res.data.result[0].isFavorite)
                    Setpageload(false)
                }

            })
    }

    const addtoFavorite = async (tutorId) => {
        await user.addToFavourite({
            tutorId: tutorId,
            isFavorite: true,
            studentId: helper.getid()
        }).then((res) => {

            if (res.data.status) {
                setIsFav(true)
            }


        })
    }


    const removeFromFavorite = async (tutorId) => {

        await user.removeFromFavourite(
            tutorId,
            helper.getid()
        ).then((res) => {
            if (res.data.status) {
                setIsFav(false)
            }

        })
    }
    return (
        <div className={`tutor-profile ${reduxState.language === 'ar' && "text-right"}`}>
                  <button className="btn back-btn py-1 ms-700 fs-14 px-3 mr-2 mb-3 cmx-4" onClick={() => history.goBack()}>{t('general.back')}</button>

            {
                !pageload &&
                <div className="row gx-0 mx-0 my-2 ">
                    <div className="col-12 mb-4">
                        <section className='row'>
                            <div className="col-lg-10 mx-auto">
                                <section className='row '>

                                    <div className="col-lg-4 mb-2 ">
                                        <p className='ms-700 mx-2 d-flex h-100 justify-content-start align-items-center'>
                                            {
                                            (data.busyInMinutes !== 0 && data.busyInMinutes !== 'NA' && data.busyInMinutes !== "0 Hrs 0 Minutes") &&
                                                <span><i className="fas fs-20 px-2 tx-green fa-caret-right"></i><span>{t('general.BusyIn')} {data.busyInMinutes}</span></span>
                                            }
                                        </p>
                                    </div>
                                    <div className='w-100 col-lg-8 mb-2 d-flex justify-content-lg-end align-items-center'>
                                        {(data.busyInMinutes !== 0 && data.busyInMinutes !== 'NA'  && data.busyInMinutes !== "0 Hrs 0 Minutes") && <button className="btn btn-tutor-profile mr-2 mb-2 text-white  px-2 py-1 fs-14 wrap ml-2" onClick={() => {
                                            if (helper.getPlanDetails().isPurchase) {
                                                history.push(`/user/start-class/${data.name}/${data.tutorId}`)
                                            } else {
                                                history.push('/user/dashboard/subscription')
                                            }
                                        }}>{t('general.startLesson')}</button>}
                                        <button className="btn btn-login  fs-14 wrap mb-2" onClick={() => history.push(`/user/dashboard/schedule/${data.tutorId}/${courseName ? courseName : ''}`)}>{t('general.scheduleCall')}</button>
                                    </div>
                                </section>
                                <div className={`d-flex tutor-big-card b-grey bg-white mb-2 pl-2 mt-2 p-stidcky tp-0`} style={{ zIndex: '1' }}>

                                    <div className="all-center my-2">
                                        <div className='p-rel mr-4'>
                                            <img style={{ height: '70px', width: '70px' }} className="rounded-circle mx-1" src={data.profilePicture ? helper.replaceS3Url(data.profilePicture) : avatar} alt="avatar" />
                                            <div className={`${data.isOnline ? "online-tag" : "offline-tag"} p-abs`} style={{ bottom: '5px', right: '5px' }}></div>
                                        </div>
                                    </div>
                                    <div className='w-100 d-flex justify-content-between'>
                                        <div className="my-2 pl-2">
                                            <p className="text-capitalize fs-18 f-fred">{data.name}</p>
                                            <p className="lh-18 fs-14 ms-500">Tutor from <span className='ms-900 text-capitalize'>{data.from}</span></p>
                                            <div className="d-flex align-items-center">
                                                <div className="d-inline-block py-0 my-0 mr-1">
                                                    <ReactStars
                                                        edit={false}
                                                        value={data.ratings ? parseFloat(data.ratings).toFixed(1) : 0}
                                                        count={5}
                                                        size={24}
                                                        isHalf={true}
                                                        emptyIcon={<i className="far fa-star"></i>}
                                                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                        fullIcon={<i className="fa fa-star"></i>}
                                                        classNames="my-0 py-0"
                                                        activeColor="#ffcb00"
                                                    />
                                                </div>
                                                <span className="tx-black ms-700 fs-18">{data.ratings ? parseFloat(data.ratings).toFixed(1) : 0}</span>
                                            </div>
                                        </div>

                                        <div className="d-flex align-items-end justify-content-end p-rel">
                                            <div className='fs-24'>
                                                {
                                                    isFav ? <i className="fas tx-red pointer hover-shadow fa-heart " onClick={() => removeFromFavorite(data.tutorId)}></i>
                                                        :
                                                        <i className="far fa-heart pointer hover-shadow" onClick={() => addtoFavorite(data.tutorId)}></i>

                                                }
                                            </div>
                                            {helper.getDateDifference(data.createdOn) < 30 && <p className="new-flag px-2 fs-18 ms-500">New</p>}
                                            <span className="p-2">
                                                <img width="30px" height="25px" src={data.flag_uri}
                                                    alt="flag" />
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className=''>
                                    <video className="br-1 bg-smoke b-grey d-block mx-auto mt-3" width="100%" src={helper.replaceVideoS3Url(data.profileVideo)} controls style={{ maxHeight: '500px' }}></video>

                                </div>
                                <div className="row mx-0 gx-0  d-flex justify-content-center  ">
                                    <div className="col-lg-6 col-6 px-1 all-center justify-content-lg-end">
                                        <button className="btn fs-14 btn-tutor-profile my-2 mr-2 " onClick={() => { store.setchatId(data.tutorId); history.push(`/user/dashboard/chat`) }}>{t('general.chatLog')}</button>

                                    </div>
                                    <div className="col-lg-6 col-6 px-1 all-center justify-content-lg-start">

                                        <button className="btn fs-14 btn-tutor-profile my-2 " onClick={() => setState({ ...state, openRatePop: true, data: data })}>{t('general.rateTutor')}</button>

                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="col-lg-5  col-12 cpx-2">
                        <section className="my-address p-3  br-1 my-3">
                            <div className="row gx-0 mx-0   mb-2">
                                <div className="col-lg-8 col-md-6 col-8 d-flex align-items-center">
                                    <p className='f-fred fs-24'>{t('general.tutorProfile.profileDetails')}</p>
                                </div>
                                <div className="col-lg-4 col-md-2 col-2 d-flex align-items-end flex-column">
                                    <img style={{ height: '50px', width: '50px' }} className="rounded-circle" src={data.profilePicture ? helper.replaceS3Url(data.profilePicture) : avatar} alt="avatar" />
                                    <div className="fs-14 ms-900 mt-1">
                                        <i className="fas fa-star tx-yellow mr-1"></i>
                                        <span>{data.ratings ? parseInt(data.ratings).toFixed(1) : 0}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="address-body tx-lt-grey fs-16">
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-4">
                                        <p className="ms-500">{t('userSidebar.tutorProfile.fullName')}</p>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-8 d-flex justify-content-center">
                                        <p className="ms-900 ml-auto text-capitalize">{data.name}</p>
                                    </div>
                                    <div className="col-12  border-dot my-1"></div>
                                    <div className="col-lg-4 col-md-4 col-4">
                                        <p className="ms-500">{t('userSidebar.tutorProfile.dob')}</p>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-8 d-flex justify-content-center">
                                        <p className="ms-900  ml-auto">{moment(data.dateOfBirth).format('MMM DD, YYYY')}</p>
                                    </div>
                                   
                                    <div className="col-12 border-dot my-1"></div>
                                    <div className="col-lg-4 col-md-4 col-4">
                                        <p className="ms-500">{t('userSidebar.tutorProfile.country')}</p>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-8 d-flex justify-content-center">
                                        <p className="ms-900  ml-auto text-capitalize">{data.from}</p>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <h4 className="f-fred mt-3 mb-2">{t('general.tutorProfile.exp&cert')}</h4>
                        <section className="row gx-0 mx-0 py-2 fs-12 b-grey px-3 my-2">
                            <div className="col-lg-12 col-8 d-flex flex-column justify-content-center">
                                <p className="fs-14 f-fred">{t('userSidebar.tutorProfile.workExp')}</p>
                                <p className="ms-500 fs-12">{t('userSidebar.tutorProfile.tutWorkExp')}</p>

                            </div>

                            <div className="col-12 mt-2 tx-lt-black ms-500 ">
                                {
                                    data.experience.map((work, index) => {
                                        return (

                                            <>
                                                <p className="ms-900 fs-12">{work.title}</p>
                                                <p className="lh-18 ms-500">{work.from} To {work.to}</p>
                                                <p className="my-2 ms-300 fs-14 tx-lt-grey">{work.description}</p>
                                            </>
                                        )
                                    })}

                            </div>
                        </section>

                        <section className="row gx-0 mx-0 py-2 fs-12 b-grey ms-500 px-3 my-2">
                            <div className="col-lg-12 col-8 d-flex flex-column justify-content-center">
                                <p className="fs-14 f-fred">{t('userSidebar.tutorProfile.edu')}</p>
                                <p className="ms-500 fs-12">{t('userSidebar.tutorProfile.tutQual')}</p>

                            </div>

                            <div className="col-12 mt-2 tx-lt-black ">
                                {data.education.map((work, index) => {
                                    return (
                                        <div key={`exp${index}`}>
                                            <p className="ms-900 fs-12">{work.degree} ({work.from} <b>To</b> {work.to} ) </p>
                                            <p className="lh-18 ms-500 mb-3 tx-lt-grey">{work.university}</p>
                                        </div>

                                    )
                                })}


                            </div>
                        </section>

                        {data.certification.length != 0 &&
                            <section className="row gx-0 mx-0 py-2 fs-12 b-grey ms-500 px-3 my-2">
                                <div className="col-lg-12 col-8 d-flex flex-column justify-content-center">
                                    <p className="fs-14 f-fred">{t('userSidebar.tutorProfile.cert')}</p>
                                    <p className="ms-500 fs-12">{t('userSidebar.tutorProfile.tutCert')}</p>

                                </div>

                                <div className="col-12  tx-lt-black mt-3">
                                    {data.certification.map((cert, index) => {
                                        return (
                                            <div key={index} className="mb-2">
                                                <p className="ms-900 fs-12 pointer">{cert.title}  </p>
                                                <p className="ms-500">{cert.issue_authority} {cert.certificate_date}</p>
                                            </div>
                                        )
                                    })}
                                </div>
                            </section>
                        }



                    </div>
                    <div className="col-lg-7 cpx-4">
                        <h4 className="f-fred mt-3 mb-2">{t('general.tutorProfile.aboutTeaching')}</h4>
                        <section className="row gx-0 mx-0 py-2 fs-12 b-grey px-3 my-2">
                            <div className="col-lg-12 col-md-8 col-8 d-flex flex-column justify-content-center">
                                <p className="fs-14 f-fred">{t('userSidebar.tutorProfile.intro')}</p>
                                <p className="ms-500 fs-12">{t('userSidebar.tutorProfile.tutIntro')}</p>

                            </div>
                            <div className="col-12 mt-2">
                                <p className="lh-18 tx-lt-black ms-300 fs-16 text-justify">{data.introduction}</p>
                            </div>
                        </section>



                        <section className="row gx-0 mx-0 py-2 fs-12 b-grey px-3 my-2">
                            <div className="col-lg-12  col-8 d-flex flex-column justify-content-center">
                                <p className="fs-14 f-fred">{t('userSidebar.tutorProfile.teachStyle')}</p>
                                <p className="ms-500 fs-12">{t('userSidebar.tutorProfile.tutorStyle')}</p>

                            </div>
                            <div className="col-12 mt-2">
                                <p className="lh-18 tx-lt-black ms-300 fs-16 text-justify">{data.teachingStyle}</p>
                            </div>
                        </section>


                        <section className="row gx-0 mx-0 py-2 fs-12 b-grey px-3 my-2">
                            <div className="col-lg-12 col-8 d-flex flex-column justify-content-center">
                                <p className="fs-14 f-fred">{t('userSidebar.tutorProfile.aboutTut')}</p>
                                <p className="ms-500 fs-12">{t('userSidebar.tutorProfile.littleBit')}</p>

                            </div>
                            <div className="col-12 mt-2">
                                <p className="lh-18 tx-lt-black ms-300 fs-16 text-justify">{data.aboutMe}</p>
                            </div>
                        </section>

                        <section className="row gx-0 mx-0 py-2 fs-12 b-grey px-3 my-2">
                            <div className="col-lg-12 col-8 d-flex flex-column justify-content-center">
                                <p className="fs-14 f-fred">{t('userSidebar.tutorProfile.lng')}</p>
                                <p className="ms-500 fs-12">{t('userSidebar.tutorProfile.accents')}</p>
                            </div>

                            <div className="col-12 mt-2 tx-lt-black mb-2">
                                {data.language.map((lng, index) =>
                                    <span key={index} className="px-2 py-1 b-grey ms-700 mx-1 mb-2 d-inline-block text-capitalize fs-16 all-center">{lng.language}( {lng.dialect}-{lng.fluency} ) </span>
                                )}

                            </div>
                        </section>


                    </div>
                    <div className="col-12 cpx-4">
                        <section className="tutor-testimonial">
                            <h4 className="f-fred mt-3 mb-2">{t('general.tutorProfile.testimonial')}</h4>


                            {
                                data.testimonials.map((result) => {
                                    return (
                                        <div className="px-2 mb-3">
                                            <Testimonial
                                                userName={result.name}
                                                country={result.country}
                                                date={result.createdOn}
                                                rate={result.ratings}
                                                review={result.review}
                                                pic={result.profilePicture}
                                            />
                                        </div>)
                                })
                            }
                            {data.testimonials.length === 0 && <p className='text-center my-3 ms-700'>{t('messages.noRecordFound')}</p>}
                        </section>
                    </div>
                </div>
            }
            {pageload && <Backdrop />}
            <RateTutor open={state.openRatePop}
                data={state.data}
                cb={() => {
                    setState({ ...state, openRatePop: false })
                }}
                onClose={() => { setState({ ...state, openRatePop: false }) }} />
        </div>
    )
}

export default UserTutors
