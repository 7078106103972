import React, { useState } from 'react'
import TimeSlots from '../component/TimeSlots';
import tutor from '../controller/tutor';
import helper from '../helper/index'
import tutorHelper from '../helper/tutor';
import moment from 'moment';
import Calendar from 'react-calendar';
import AlertPopUp from '../component/AlertPopUp';
import Backdrop from '../component/Backdrop';
import { useHistory } from 'react-router-dom';
import avatar from '../static/images/avatar.png'
import { useTranslation } from "react-i18next";
export default function ResheduleClass({ close, cb, slotData, callData, callDate }) {
    const { t } = useTranslation();
    const [error, setError] = useState("")
    const [isCancelStatus, setIsCancelStatus] = useState('')
    const [reason, setReason] = useState({ open: false, data: '' })
    const [slotList, setSlotList] = useState([])
    const [pageload, setPageload] = useState(false)
    const [selectDate, setSelectDate] = useState()
    const [interval, setInterval] = useState([])
    const history = useHistory()
    const [activeIndex, setActiveIndex] = useState({
        intervalIndex: -1,
        slotIndex: -1
    })

    const [showPop, setShowPop] = useState({
        open: false, type: 'ok', msg: ""
    })

    const rescheduleClass = async () => {
        var formated_startTime = moment(`${callData.start_time}`, 'hh:mm:ss');
        var formated_endTime = moment(`${moment(callData.end_time, 'hh:mm').format('hh:mm:ss')}`, 'hh:mm:ss');
        var dif = moment.duration(formated_endTime.diff(formated_startTime));
        let duration = [tutorHelper.makeTwoDigit(dif.hours()), tutorHelper.makeTwoDigit(dif.minutes())].join(' : ');


        const reqData = {
            tutor_id: helper.getid(),
            student_id: callData.student_id,
            start_time: moment(interval[activeIndex.intervalIndex], 'hh:mm').format('HH:mm'),
            channel_name: callData.channel_name,
            date: moment(selectDate).format('YYYY-MM-DD'),
            end_time: tutorHelper.addMinutes(interval[activeIndex.intervalIndex], duration),
            userType: 'tutor',
            slot_minutes: dif.minutes(),
            reason: reason.data
        }


        setError('')
        setPageload(true)
        await tutor.rescheduleClass(reqData)
            .then((res) => {
                if (res.status === 200) {
                    setShowPop({ ...showPop, open: true, msg: "You have successfully reshedule the class.", type: 'ok' })
                }
                else {
                    setShowPop({ ...showPop, open: true, msg: res.data.error, type: 'fail' })
                }
                setPageload(false)
            })
    }

    const cancelClass = async () => {
        const reqData = {
            tutor_id: helper.getid(),
            channel_name: callData.channel_name,
            status: "Cancel",
            reason: reason.data,
        }
        if (!reason.data) {
            setError("Please give reason")
            return
        }
        setPageload(true)
        await tutor.cancelClass(reqData)
            .then((res) => {
                if (res.data.status) {
                    setShowPop({ ...showPop, open: true, msg: "You have successfully cancel the class.", type: 'ok' })
                }
                else {
                    setShowPop({ ...showPop, open: true, msg: res.data.error, type: 'fail' })
                }
                setPageload(false)
            })
    }

    const onChangeCalendar = (day) => {
        setSelectDate(day)
        let showData = ''
        slotData.some((data) => {
            if (data.date.toString() === moment(day).format('YYYY-MM-DD')) {
                showData = data.slot
                return true
            }else{
                return false
            }
        })

        setSlotList(showData)
    }

    const getInterval = (startTime, endTime) => {
        let st = moment(startTime, 'hh:mm').format('HH : mm')
        let en = moment(endTime, 'hh:mm').format('HH : mm')
        var formated_startTime = moment(`${callData.start_time}`, 'hh:mm:ss');
        var formated_endTime = moment(`${moment(callData.end_time, 'hh:mm').format('hh:mm')}`, 'hh:mm:ss');
        var dif = moment.duration(formated_endTime.diff(formated_startTime));
        let duration = [tutorHelper.makeTwoDigit(dif.hours()), tutorHelper.makeTwoDigit(dif.minutes())].join(' : ');

        let list = tutorHelper.getSlots(st, en, duration)
        setInterval(list)
    }

    return (
        <div className="row mt-3">
            <div className="col-12 mb-4 d-flex justify-content-between">
                <div>

                    <AlertPopUp
                        open={showPop.open}
                        type={showPop.type}
                        msg={showPop.msg}
                        onClose={() => { setShowPop({ ...showPop, open: false }); cb(); close() }}
                    />

                    <p className="ms-500 fs-14 lh-18">Date</p>
                    <p className="ms-900 fs-18 text-capitalize">{callDate}</p>
                </div>

                {
                    helper.isUserCanJoinClass({ classDate: callDate, startTime: callData.start_time, endTime: callData.end_time }) && callData.status === "Booked" &&
                    <button className="btn btn-login  my-2" onClick={() => history.push(`/dhad-video/${callData.channel_name}`)}>{t('button.joinClass')}</button>
                }

            </div>

            <div className="col-lg-8 col-md-6 col-12 pr-2 mb-2 time-slot-container">
                <TimeSlots
                    time={
                        <div>
                            <p className='ms-900 d-inline-block'>{moment(callData.start_time, 'hh:mm').format('hh : mm A')}</p>
                        </div>
                    }
                    status={callData.status}
                    bgClass={"time-slots-booked"}
                />
            </div>
            <div className="col-12 my-2">
                <div className="b-grey px-2 py-2 ms-500 my-2">
                    <div className="d-flex gx-0 mt-2 ">
                        <div className='d-flex w-50'>
                            <div className="mr-2">
                                <img style={{ height: '40px', width: '40px' }} className="rounded-circle" src={callData.profilePicture ? helper.replaceS3Url(callData.profilePicture) : avatar} alt="avatar" />
                            </div>
                            <div className="d-flex flex-column justify-content-center">
                                <p className="f-fred lh-15 fs-14">{callData.name ? callData.name : 'Anonymous'}</p>
                                <p className="fs-12 tx-lt-grey ms-700">From India</p>
                            </div>
                        </div>
                        <div className='w-50'>
                            <p className='f-fred fs-14 text-right'>{moment(callData.start_time, 'hh:mm').format('hh : mm A')} - {moment(callData.end_time, 'hh:mm').format('hh : mm A')}</p>
                        </div>
                    </div>
                </div>
            </div>

            {
                (((moment(callDate).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) && (
                    moment(callData.start_time, 'HH:mm').format('HH:mm') > moment().format("HH:mm")
                ))
                    || (moment(callDate).format("YYYY-MM-DD") > moment().format("YYYY-MM-DD")))
                &&
                callData.status === "Booked" &&
                <div className="col-12">
                    {
                        isCancelStatus === "" &&
                        <button className='btn btn cancel-subscription py-2 my-1 fs-14 f-fred ls w-50' onClick={() => { setIsCancelStatus("cancel") }}>CANCEL THIS CALL</button>}
                </div>
            }

            {
                isCancelStatus === "cancel" &&
                <div className="col-12 tutor-dash h-100">
                    <textarea onChange={(e) => { setReason({ ...reason, data: e.target.value }) }} placeholder='Reason for cancellation' cols="30" style={{ height: 'initial' }} className="input-box w-100 p-2 ms-700 px-2" rows="6">
                    </textarea>
                </div>
            }
            {
                isCancelStatus === "reschedule" &&
                <>
                    <section className="calendar">
                        <Calendar
                            onChange={(day) => { onChangeCalendar(day) }}
                            tileClassName="tilename"
                            className="w-100 cl-body br-1 mt-3 ms-700 selected"
                            navigationAriaLabel="go up"
                            minDate={new Date()}
                            maxDate={new Date(moment().endOf('month'))}
                            maxDetail='month'
                            tileDisabled={({ activeStartDate, date, view }) => {
                                let minDate = moment(new Date()).format('YYYY-MM-DD')
                                let formatedDate = moment(date).format('YYYY-MM-DD')
                                if (formatedDate < minDate) {
                                    return true
                                }
                                else {
                                    return false
                                }


                            }}
                            tileContent={({  date }) => {
                                let find = false
                                slotData.some((data) => {
                                    if (data.date.toString() === moment(date).format('YYYY-MM-DD')) {
                                        find = true
                                        return find
                                    }else{
                                        return find
                                    }
                                })
                                if (find) {
                                    return <div className='all-center mt-2 p-rel' >
                                        <p className='p-rel' style={{ borderBottom: '3px solid green', borderRadius: '10px', width: '20px' }}>
                                            <i className='fas fa-clock  fs-12 p-abs tx-green' style={{ bottom: '0px', right: '-10px' }}></i>
                                        </p>
                                    </div>
                                }else{
                                    <></>
                                }
                            }}
                        />
                        <section className="mt-2">
                            <p className="fs-16 ms-900 mb-2">Select the slot</p>
                            {slotList.map((data, index) => {
                                if (data.status !== "Booked") {
                                    return (
                                        <div>
                                            <span
                                                onClick={() => { getInterval(data.start_time, data.end_time); setActiveIndex({ ...activeIndex, slotIndex: index }) }}
                                                key={`slot${index}`}
                                                className={`p-3 mb-2 pointer br-1 mr-2 d-inline-block ms-500 fs-12
                                                ${activeIndex.slotIndex === index && 'active-time'}
                                            ${data.status === "Available" ? "available-time" : "unavailable-time"}`}>
                                                <div>
                                                    <p className='fs-14 d-inline-block'>{moment(data.start_time, 'hh:mm').format('hh : mm A')}</p>
                                                    <p className='text-center d-inline-block mx-2 fs-12'>To</p>
                                                    <p className='fs-14 d-inline-block'>{moment(data.end_time, 'hh:mm').format('hh : mm A')}</p>
                                                </div>
                                                <p className="text-center lh-15 fs-14">{data.status}</p>
                                            </span>
                                            {activeIndex.slotIndex === index &&
                                                <div>
                                                    <hr className='my-2' />
                                                    {interval.map((intervals, index) =>
                                                        <span onClick={() => { setActiveIndex({ ...activeIndex, intervalIndex: index }) }} key={`interval${index}`} className={`ms-700 pointer ${activeIndex.intervalIndex === index && 'active-time'} available-time d-inline-block m-2 fs-14 p-2`}>{moment(intervals, 'hh:mm').format('hh : mm A')}</span>
                                                    )}

                                                    <div className="col-12 tutor-dash h-100">
                                                        <label htmlFor="" className="mb-2 ms-500">Reason</label>
                                                        <textarea onChange={(e) => { setReason({ ...reason, data: e.target.value }) }} placeholder='Why you want to reschedule the class?' cols="30" style={{ height: 'initial' }} className="input-box w-100 p-2 ms-700 px-2" rows="3">
                                                        </textarea>
                                                    </div>
                                                    <hr className='my-2' />
                                                </div>
                                            }
                                        </div>
                                    )
                                }else{
                                   return <></>
                                }

                            })
                            }

                        </section>
                    </section>
                </>
            }
            <div className="col-12">
                <p className="my-2 ms-500 fs-14 tx-error text-center">{error}</p>
            </div>

            {
                isCancelStatus === "cancel" &&
                <div className="col-lg-9 mt-2 mb-2 ">

                    <button className='btn btn cancel-subscription py-2 my-1 fs-14 f-fred ls w-100' onClick={() => { cancelClass() }}>CONFIRM & CANCEL THIS CALL</button>

                </div>
            }

            {
                isCancelStatus === "reschedule" &&
                <button className="btn btn-tutor-profile mt-2 fs-12" onClick={rescheduleClass}>Select & schedule a call</button>

            }

            {pageload && <Backdrop />}

        </div >

    )
}
