/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react'
import fb from '../config/firebaseAuth';
import helper from '../helper/index';
import { ref, push, onChildAdded } from "firebase/database";
import Slide from 'react-reveal/Slide';
import moment from 'moment';
import { FaTelegramPlane, FaArrowLeft } from 'react-icons/fa';
import { useTimer } from 'react-timer-hook';
import StudentProfile from './StudentProfile';
import StudentCourse from './StudentCourse';
import TutorLibrary from './TutorLibrary';
import tutorHelper from '../helper/tutor'
import avatar from '../static/images/avatar.png'
import { setRemoteUserName } from '../redux/AppState';
import { Menu } from '@mui/material';
import { toast } from 'react-toastify';
import { encryptChatMessages } from '../helper/utils';

const db = fb.database
let prevDate = "Today"
let condition = 0
let rootRoomid = 0
let unsubscribe = ''

const adduserDiv = (message, time) => {
    try {
        const userDiv = document.getElementById("chat-body-mb")
        if (condition === 0 || (prevDate.toString() !== moment(time).format('MMMM DD, YYYY').toString())) {
            prevDate = moment(time).format('MMMM DD, YYYY')
            const chatDayBox = document.createElement('div')
            const chatDay = document.createElement('div')
            chatDayBox.classList.add("chat-day-box")
            chatDay.classList.add("chat-day")
            chatDayBox.append(chatDay)
            chatDay.innerHTML = moment(time).format('MMMM DD, YYYY')
            userDiv.append(chatDayBox)
            condition = 1;
        }

        const ob = document.createElement('div')
        const chatDivContainer = document.createElement('div')
        const chatbox = document.createElement('div')
        const timeDiv = document.createElement('div')
        const clearDiv = document.createElement('div')
        chatDivContainer.classList.add("user-chat-box-container")
        chatbox.classList.add("user-chat-box-body")
        timeDiv.classList.add("chat-time")
        chatDivContainer.append(chatbox)
        chatDivContainer.append(timeDiv)
        chatbox.innerHTML = message
        timeDiv.innerHTML = moment(time).format('hh : mm a')
        clearDiv.classList.add("clear")
        ob.classList.add("p-rel")
        ob.append(chatDivContainer)
        ob.append(clearDiv)
        userDiv.append(ob)
        userDiv.scrollTop = userDiv.scrollHeight;
    }
    catch (err) {

    }
}

const addRemoteDiv = (message, time) => {
    try {
        const remoteDiv = document.getElementById("chat-body-mb")
        if (condition === 0 || (prevDate.toString() !== moment(time).format('MMMM DD, YYYY').toString())) {
            prevDate = moment(time).format('MMMM DD, YYYY')
            const chatDayBox = document.createElement('div')
            const chatDay = document.createElement('div')
            chatDayBox.classList.add("chat-day-box")
            chatDay.classList.add("chat-day")
            chatDayBox.append(chatDay)
            chatDay.innerHTML = moment(time).format('MMMM DD, YYYY')
            remoteDiv.append(chatDayBox)
            condition = 1;
        }
        const ob = document.createElement('div')
        const chatDiv = document.createElement('div')
        const clearDiv = document.createElement('div')
        chatDiv.innerHTML = message
        chatDiv.classList.add("remote-chat-box")

        const timeDiv = document.createElement('div')
        timeDiv.classList.add("chat-time", "pl-3")
        timeDiv.style.clear = "both"
        timeDiv.innerHTML = moment(time).format('hh : mm a')

        clearDiv.classList.add("clear")
        ob.classList.add("p-rel")
        ob.append(chatDiv)
        ob.append(timeDiv)
        ob.append(clearDiv)
        remoteDiv.append(ob)
        remoteDiv.scrollTop = remoteDiv.scrollHeight;

    }
    catch (err) {

    }
}

const enable = () => {
    unsubscribe = onChildAdded(ref(db, `classChat/${rootRoomid}`), (data, index) => {
        const val = data.val()
        if (val.createdBy == helper.getid()) {
            adduserDiv(val.message, val.createdAt)
        }
        else {
            addRemoteDiv(val.message)
        }
    })
}

export default function ChatOnMobile({ myId, studentId, roomId, data, onClassEnd, onClose, isAttendanceMark, chatBotFor = "InstantClass" }) {

    let msg = useRef()
    const [state, setState] = useState({
        openProfile: false,
        openCourseHistory: false,
        browseMyLibrary: false,
        menuRef: null,
        openMenu: false
    })
    const [studentInfo, setStudentInfo] = useState('')

    const setTimer = () => {
        const time = new Date();
        try {
            if (data) {
                const classEndTime = moment(`${moment(data.end_time, 'HH:mm').format('HH:mm:ss')}`, 'HH:mm:ss');
                const classOrgStartTime = moment(`${moment(data.start_time, 'HH:mm').format('HH:mm:ss')}`, 'HH:mm:ss');
                const classStartTime = moment(`${moment(new Date(), 'HH:mm').format('HH:mm:ss')}`, 'HH:mm:ss');
                if (data.available_minutes == 0) {/* available minutes zero so user cannot join the call */
                    onClassEnd()
                    return
                }

                var actualDif = moment.duration(classEndTime.diff(classStartTime));
                var virtualDif = moment.duration(classEndTime.diff(classOrgStartTime));

                if (chatBotFor === "InstantClass") {
                    time.setSeconds(virtualDif.minutes() * 60 + virtualDif.seconds() + time.getSeconds())
                } else {
                    if (actualDif.minutes() <= data.available_minutes) { /* available minutes are greater than schedule minutes */
                        if ((virtualDif.minutes() * 60 + virtualDif.seconds()) - (actualDif.minutes() * 60 + actualDif.seconds()) <= 100) {
                            time.setSeconds(virtualDif.minutes() * 60 + virtualDif.seconds() + time.getSeconds())
                        } else {
                            time.setSeconds(time.getSeconds() + ((actualDif.minutes() * 60) + actualDif.seconds()))
                        }
                    } else {
                        time.setSeconds(time.getSeconds() + ((data.available_minutes * 60)))
                    }
                }
            }
            return time
        }
        catch (er) { return time }
    }

    const { seconds, minutes, start, pause } = useTimer({
        expiryTimestamp: setTimer(), autoStart: true, onExpire: () => {
            onClassEnd()
        }
    });


    useEffect(() => {
        rootRoomid = roomId
        enable()
        condition = 0
        return () => { try { unsubscribe() } catch { } }
    }, [])

    const pushMessages = () => {
        const date = new Date()
        if (msg.current.value) {
            encryptChatMessages(msg.current.value, studentId, async (data) => {
                if (!data.isValid) {
                    toast.error("Illegal activity detected.")
                }
                push(ref(db, `classChat/${roomId}`), {
                    message: data.msg,
                    createdBy: myId,
                    createdAt: moment(date).format('YYYY-MM-DD hh:mm:ss A')
                });
                msg.current.value = ""
            })
        }
    }

    return (
        <div className="chat-box ltr p-fix tp-0 lt-0 h-100 mx-auto px-0 w-100 d-lg-none d-md-none" style={{ zIndex: '9' }}>
            <Slide left>
                <div className='d-flex justify-content-between flex-column' style={{ height: '100vh' }}>
                    <div className="chat-header  py-3 px-2 br-1">
                        <div className="row gx-0 mx-0">
                            <div className="col-1">
                                <FaArrowLeft size={24} onClick={onClose} className='inactive-icon-color' />
                            </div>
                            <div className="col-9">
                                <p className="ms-900 fs-18 tx-lt-black">{"Messages"}</p>
                            </div>
                            <div className="col-2">
                                {helper.getRoleType() === "tutor" &&
                                    <section>
                                        <i className="fas fa-ellipsis-v c-dropdown-icon pointer ml-2 hover-shadow" onClick={(e) => setState({ ...state, openMenu: true, menuRef: e.currentTarget })}></i>
                                        <Menu
                                            anchorEl={state.menuRef}
                                            open={state.openMenu}
                                            onClose={(e) => setState({ ...state, openMenu: false, menuRef: e.currentTarget })}>
                                            <div className='mobile-nav fs-16  ms-700 '>
                                                <ul style={{ listStyle: 'none' }}>
                                                    <li onClick={() => { setState({ ...state, openProfile: true, openMenu: false }) }}>Student profile</li>
                                                    <li onClick={() => { setState({ ...state, openCourseHistory: true, openMenu: false }) }}>Course history</li>
                                                </ul>
                                            </div>
                                        </Menu>
                                    </section>
                                }
                            </div>
                        </div>
                    </div>

                    <div id="chat-body-mb" className="chat-room-body px-2  chat-body h-100">
                        {/* user information  */}
                        {helper.getRoleType() === "tutor" ?
                            <section className={`d-flex tutor-big-card m-2 b-grey bg-white mb-2 px-2 py-2 mt-2 p-sticky tp-0`} style={{ zIndex: '1' }}>
                                <div className="all-center my-2">
                                    <div className='p-rel mr-2'>
                                        <img style={{ height: '50px', width: '50px' }} className="rounded-circle mx-1" src={studentInfo && studentInfo.profilePicture ? helper.replaceS3Url(studentInfo.profilePicture) : avatar} alt="avatar" />
                                    </div>
                                </div>

                                <div className='w-100 d-flex justify-content-between'>
                                    <div className="my-2 pl-1 d-flex mr-5 flex-column justify-content-center">
                                        <p className="text-capitalize fs-16 f-fred tx-lt-grey">{studentInfo && studentInfo.name}</p>
                                        <p className='ms-500 fs-14 tx-lt-grey'>{studentInfo && studentInfo.country}</p>
                                    </div>
                                    <div className="d-flex flex-column align-items-end justify-content-center p-rel">
                                        <p className='ms-500 fs-14'>Call will end in</p>
                                        <p className='ms-700 fs-26'>  {tutorHelper.makeTwoDigit(minutes)}m:{tutorHelper.makeTwoDigit(seconds)}s</p>
                                    </div>
                                </div>
                            </section>
                            :
                            <section className={`d-flex tutor-big-card m-2 b-grey bg-white mb-2 px-2 py-2 mt-2 p-sticky tp-0`} style={{ zIndex: '1' }}>
                                <div className="all-center my-2">
                                    <div className='p-rel mr-2'>
                                        <img style={{ height: '50px', width: '50px' }} className="rounded-circle mx-1" src={studentInfo && studentInfo.tutor_profile_pic ? helper.replaceS3Url(studentInfo.tutor_profile_pic) : avatar} alt="avatar" />
                                    </div>
                                </div>

                                <div className='w-100 d-flex justify-content-between'>
                                    <div className="my-2 pl-1 d-flex mr-5 flex-column justify-content-center">
                                        <p className="text-capitalize fs-16 f-fred tx-lt-grey">{studentInfo && studentInfo.tutor_name}</p>
                                        <p className='ms-500 fs-14 tx-lt-grey'>{studentInfo && studentInfo.tutor_country}</p>
                                    </div>
                                    <div className="d-flex flex-column align-items-end justify-content-center p-rel">
                                        <p className='ms-500 fs-14'>Call will end in</p>
                                        <p className='ms-700 fs-26'>  {tutorHelper.makeTwoDigit(minutes)}m:{tutorHelper.makeTwoDigit(seconds)}s</p>
                                    </div>
                                </div>
                            </section>
                        }

                    </div>
                    {/* <section className='chat-footer-container'>
                        < form onSubmit={(e) => { e.preventDefault(); pushMessages() }}>
                            <div className="chat-footer  py-2 px-2 d-flex mx-2 align-items-center">
                                <input type="text" ref={msg} placeholder="Type a message here ..." className="chat-input-box w-100 h-100 ms-700" />
                                <button type="submit" className="btn chat-send pointer br-1 all-center"><FaTelegramPlane className='fs-30 text-white' /></button>
                            </div>
                        </form >
                        <section className='w-100' style={{ height: '60px' }}>
                        </section>
                    </section> */}
                    <section className='pb-2 chat-footer-container'>
                        < form onSubmit={(e) => { e.preventDefault(); pushMessages() }}>
                            <div className="chat-footer  py-2 px-2 d-flex mx-2 align-items-center">
                                <input type="text" ref={msg} placeholder="Type a message here ..." className="chat-input-box w-100 h-100 ms-700" />
                                <button type="submit" className="btn chat-send pointer br-1 all-center"><FaTelegramPlane className='fs-30 text-white' /></button>
                            </div>
                        </form >
                    </section>

                </div>
            </Slide>

            {/* Side bar details  */}
            <StudentProfile open={state.openProfile} onClose={() => setState({ ...state, openProfile: false })} cb={(data) => { setStudentInfo(data); setRemoteUserName(data && helper.getRoleType() === "tutor" ? data.name : data.tutor_name) }} />
            {state.openCourseHistory && <StudentCourse isAttendanceMark={isAttendanceMark} studentId={studentId} open={state.openCourseHistory} browse={state.browse} onClose={() => setState({ ...state, openCourseHistory: false })} />}
            {state.browseMyLibrary && <TutorLibrary onClose={() => setState({ ...state, browseMyLibrary: false })} />}

        </div>
    )
}
