/* eslint-disable */
import React, { useState, useRef, useCallback } from "react";
import Webcam from "react-webcam";
import { dataURLtoFile } from "../helper/utils";

export default function CapturePhoto({ onCapture, onClose }) {
  const webcamRef = useRef(null);
  const [facingMode, setFacingMode] = useState("user"); // "user" for front camera, "environment" for back camera

  const videoConstraints = {
    width: 300,
    height: 250,
    facingMode: facingMode,
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      const file = dataURLtoFile(imageSrc, "pic.jpeg");
      onCapture(file);
    }
  }, [webcamRef, onCapture]);

  const toggleCamera = () => {
    setFacingMode((prev) => (prev === "user" ? "environment" : "user"));
  };

  return (
    <div className="flex-column all-center p-2">
      <Webcam
        audio={false}
        ref={webcamRef}
        imageSmoothing={true}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
      />
      <div className="d-flex justify-content-between gap-1 align-items-center mt-2 w-100">
        <button
          className="btn btn-login my-2 mr-1"
          style={{ width: "120px" }}
          onClick={capture}
        >
          Capture
        </button>
        <button
          className="btn btn-login fs-3 my-2 mr-1"
          style={{ width: "120px" }}
          onClick={toggleCamera}
        >
          Switch
        </button>
        <button
          className="btn btn-login my-2"
          style={{ width: "120px" }}
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
